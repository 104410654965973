@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-cell {
  width: 30px;
  height: 30px;

  border: solid 1px #ccc;
  border-collapse: collapse;

  &.active {
    outline: solid 2px #aaa;
  }

  &.grid-cell-None {
    background-color: #F9F7EE;
  }

  &.grid-cell-Box {
    background-color: #BCB8A5;
    border: solid 1px #928A6B;
  }

  &.grid-cell-Fixed {
    background-color: #878490;
    border: solid 1px #686476;
  }

  &.grid-cell-Exit {
    background-color: #B8C2B6;
    border: solid 1px #7E9179;
  }

  &.grid-cell-Killer {
    background-color: #e59584;
    border: solid 1px #ad3e25;
  }

  &.grid-cell-SemiFixed {
    background-color: #F9F7EE;
    border: solid 3px #686476;
  }

  &.grid-cell-SemiKiller {
    background-color: #F9F7EE;
    border: solid 3px #ad3e25;
  }
}

#viewport {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  top: 0;
  left: 400px;
  right: 0;
  bottom: 0;
  background: #F9F7EE;
}

.grid {
  border: solid 1px #aaa;

  .grid-row {
    display: flex;

    /*.grid-cell {
      border: none;
      border-left: solid 1px #aaa;
      border-top: solid 1px #aaa;

      &:last-child {
        border-right: solid 1px #aaa;
      }
    }

    &:last-child {
      border-bottom: solid 1px #aaa;
    }*/
  }
}